import React from "react"
import RichText from "../rich-text";

const TopBar = (props) => {
  const { content, bgColor } = props.data;
  return (
    <div className="my-[10px] p-0 text-small" style={{ backgroundColor: `#${bgColor.hex}` }}>
      <div className="p-2">
        <RichText className="text-small" value={content.data} />
      </div>
    </div>
  )
}

export default TopBar
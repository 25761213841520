import React from "react";
import Module from "../components/modules";

const RenderModules = ({ modules }) => {
	if (!modules) return;

	return modules?.map((module) => <Module key={module._key} type={module._type} moduleData={module} />);
};

export default RenderModules;

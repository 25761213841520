import * as styles from "./style.module.css";
import { format } from "date-fns";
import AuthorList from "../author-list";
import Container from "../container";
import React from "react";
import RenderModules from "../../helpers/render-modules";

const BlogPost = (props) => {
  const { title, categories, authors, modules, _updatedAt } = props;

  return (
    <article className="flex flex-col justify-center">
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className="font-semibold my-[1rem]">{title}</h1>
            <div className="flex flex-row flex-wrap items-center my-5 gap-1">
              {authors && <AuthorList items={authors} />}
              <span className="hidden md:block">|</span>
              <span>Updated at: {format(new Date(_updatedAt), "MMMM Mo, yyyy")}</span>
            </div>
          </div>
          <aside className={styles.metaContent}>
            <div className={styles.content}>
              <RenderModules modules={modules} />
            </div>
            {categories && (
              <div className="flex flex-col">
                <h3>Categories</h3>
                <div className="flex flex-wrap gap-2 mt-5">
                  {categories.map((category) => (
                    <div className="bg-[#354683] px-3 py-2 rounded-2xl text-white" key={category._id}>{category.title}</div>
                  ))}
                </div>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;

// extracted by mini-css-extract-plugin
export var productContainer = "style-module--productContainer--8aad1";
export var ratingList = "style-module--ratingList--e4d0a";
export var ratingStars = "style-module--ratingStars--57761";
export var prodStars = "style-module--prodStars--cd358";
export var productBox = "style-module--productBox--e31d0";
export var productImage = "style-module--productImage--cfdf6";
export var productStars = "style-module--productStars--dddc3";
export var productText = "style-module--productText--35812";
export var productTitle = "style-module--productTitle--39d1a";
export var productLink = "style-module--productLink--b9693";
export var recommended = "style-module--recommended--f408b";
export var recommendationBestLabelContainer = "style-module--recommendationBestLabelContainer--48f32";
export var recommendationBestLabel = "style-module--recommendationBestLabel--a3183";
export var additionalTextContainer = "style-module--additionalTextContainer--9faf0";
export var ratingText = "style-module--ratingText--be8f0";
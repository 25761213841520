import React from "react";

const AuthorList = ({ items }) => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <span>by</span>
      {items.map(({ author, _key }, index) => {
        const authorName = author && author.name;
        return (
          <span className="" key={_key}>{authorName || <em>Missing name</em>}</span>
        );
      })}
    </div>
  );
}

export default AuthorList;

import Link from "../../helpers/link";
import React from "react";
import Image from "../image";

const CheckPrices = (props) => {
  const { image, button, title, description } = props.data;
  return (
    <div className="flex flex-col md:flex-row px-[5px] py-[10px] h-fit clear-both relative mb-5 rounded-[3px] shadow-xl justify-center">
      <div className="md:w-[36%] flex justify-center">
        <Image image={image} />
      </div>
      <div className="md:w-[64%] p-[5px] flex flex-col m-auto">
        <div className="py-[5px] px-[10px]">
          <span className="font-semibold w-full text-body border-b-[4px] border-b-[#354683] pb-[2px] border-b-solid">{title}</span>
        </div>
        <div className="mt-[15px] px-[10px] py-[5px]">
          <p>{description}</p>
        </div>
        <div className="w-full text-left md:px-[10px] my-2">
          <Link to={button?.url}
            className={`btn-primary mt-10 btn-center`}>
            {button?.text}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CheckPrices
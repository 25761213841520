import React from "react";
import RichText from "../rich-text";

const ContentVideo = (props) => {
  const { content, youtubeUrl } = props.data;
  return (
    <div className="w-full my-8 flex flex-col">
      <div>
        <RichText value={content} />
      </div>
      <div className="my-7 md:w-11/12">
        <iframe title={youtubeUrl} width="100%" height="400px" src={youtubeUrl} />
      </div>
    </div>
  )
}

export default ContentVideo;
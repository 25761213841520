import React from "react";
import products from "../products";
import richText from "../../components/rich-text";
import checkPrices from "../check-prices";
import topBar from "../top-bar";
import contentVideo from './../content-video/index';

const _module2Component = {
	richText: richText,
	topBar: topBar,
	contentVideo: contentVideo,
	checkPrices: checkPrices,
	products: products,
};

const Module = ({ moduleData, type }) => {
	const RequestedModule = _module2Component[type];
	if (RequestedModule) return (
		<div className="my-4">
			<RequestedModule data={moduleData} />
		</div>
	);

	return <span>{type}</span>;
};

export default Module;

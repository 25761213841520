import BlogPost from "../components/blog-post";
import React from "react";
import Layout from "../containers/layout";
import Seo from "../components/seo";
import { toPlainText } from "../lib/helpers";

const BlogPostTemplate = (props) => {
  const { post, settings } = props.pageContext;
  return (
    <Layout
      logo={settings.logo}
      header={settings.header}
      footer={settings.footer}
    >
      {post && (
        <Seo
          title={post.title || "Untitled"}
          description={toPlainText(post.excerpt)}
          image={post.mainImage}
        />
      )}

      {post && <BlogPost {...post} />}
    </Layout>
  );
};

export default BlogPostTemplate;

import React from 'react'
import Image from "../../components/image";
import Link from "../../helpers/link";
import { PortableText } from "@portabletext/react";
import { getAspectRatio } from "../../lib/helpers";
import * as styles from "./style.module.css";


const Products = (props) => {

  const TableSerializer = ({ value: node }) => {
    const headers = node.rows[0].cells.map((header, i) => (
      <th className="border text-h3 py-3 px-10 font-body font-bold" key={i}>
        {header}
      </th>
    ));
  
    const tableRows = node.rows.slice(1).map((tr, i) => (
      <tr key={i}>
        {tr.cells.map((cell, i) => {
          return (
            <td className="border py-3 px-10" key={i}>
              {cell}
            </td>
          );
        })}
      </tr>
    ));
  
    return (
      <table className="border my-5">
        <thead>
          <tr className="border">{headers}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };
  
  //Types
  
  const ImageRenderer = ({ value: node }) => {
    return (
      <div className="my-5 flex justify-center">
        <Image image={node} to={node.url} className={getAspectRatio(node?.ratio)} />
      </div>
    );
  };
  
  const ButtonRenderer = ({ value: node }) => {
    return (
      <div className="flex justify-center">
        <Link className="btn-secondary w-full md:w-[40%] m-auto" to={node?.url}>
          {node?.text}
        </Link>
      </div>
    );
  };
  
  // Marks
  
  const Links = ({ children, value }) => {
    return <Link className="text-[#00F] underline" to={value.href}>{children}</Link>;
  };
  
  const Color = ({ children, value }) => {
    return <span style={{ color: value.hex }}>{children}</span>;
  };
  
  const Border = (props) => {
    const { children, value } = props;
  
    const border = "2px solid #324983";
    return <span style={{
      borderBottom: value.bottom && border,
      borderTop: value.top && border,
      borderRight: value.right && border,
      borderLeft: value.left && border,
    }}>{children}</span>
  }
  
  const richTextComponents = {
    types: {
      table: TableSerializer,
      Image: ImageRenderer,
      Button: ButtonRenderer,
    },
  
    marks: {
      link: Links,
      color: Color,
      border: Border,
    },
  };

  const {singleItem} = props?.data;

  console.log("props", props);
  return (
    <div className="flex flex-col">
      {singleItem?.map((data, i) => {

        let ratingProdct;
        let productMessage;

        if ( data?.stars < 1.1 && 0.1 < data?.stars ) {
          ratingProdct = "20%";
          productMessage="Unsatisfied";
        }
        else if (data?.stars < 2.0 && 1.1 < data?.stars) {
          ratingProdct = "40%"
          productMessage="Okay"
        }
        else if (data?.stars < 3.0 && 2.1 < data?.stars) {
          ratingProdct = "60%";
          productMessage="Good"
        }
        else if (data?.stars < 4.0 &&  3.1 < data?.stars) {
          ratingProdct = "80%";
          productMessage="Great"
        }
        else {
          ratingProdct = "100%";
          productMessage="Very satisfied"
        }


        return (
          <div className={`${styles.productContainer} ${data?.bestPillow ? `${styles.recommended} `: ""}`} key={i}>
            {data?.bestPillow && <div className={styles.recommendationBestLabelContainer}>
              <span className={styles.recommendationBestLabel}>Sleep foundation best buy</span>
              </div>}
              <div className={styles.productBox}>
            <div className={styles.productImage}>
              <Image image={data?.image}/>
          
              </div>
              <div className={styles.productText}>
                <p className={styles.productTitle}>{data?.title}</p>
              <PortableText value={data?.benefits} components={richTextComponents}/>
                </div>
                <div className={styles.productStars}>
                  <p className={styles.prodStars}>{data?.stars}</p>
                  <div className={styles.ratingList}>
                    <span className={styles.ratingStars} style={{ width: ratingProdct}}></span>
                  </div>
                  <p className={styles.ratingText}>{productMessage}</p>
                  </div>
                <div className={styles.productLink}>
                  <div className={styles.additionalTextContainer}>
                <PortableText value={data?.additionalText} components={richTextComponents}/>
                </div>
                  <button>
                  <Link to={data?.url}>Find out more
                  </Link>
                  </button>
                 
                  </div>
                  </div>
            </div>

        )
      })}
    </div>
  )
}

export default Products;
// extracted by mini-css-extract-plugin
export var root = "style-module--root--32fe6";
export var title = "style-module--title--de965 typography-module--responsiveTitle1--d2dd2";
export var mainImage = "style-module--mainImage--1bad5";
export var grid = "style-module--grid--d6ed3";
export var mainContent = "style-module--mainContent--20a14";
export var metaContent = "style-module--metaContent--3b948";
export var content = "style-module--content--03976";
export var publishedAt = "style-module--publishedAt--070e3 typography-module--small--2877f";
export var categories = "style-module--categories--91562";
export var categoriesHeadline = "style-module--categoriesHeadline--cd502 typography-module--base--16985";